






import Vue from 'vue'
import { mapActions } from 'vuex'
import api from '@/plugins/api'
import { errorMessages } from '@/utils/helpers'
export default Vue.extend({
  name: 'QuickLoginButton',
  props: {
    payload: {
      type: Object,
      default: Function,
    },
  },
  methods: {
    ...mapActions(['quickLogin']),
    async quickLogIn() {
      const loader = this.$loading.show()
      try {
        const res = await api().post('/dev/quick-login', this.payload)
        await this.quickLogin(res)
        await this.$router.push({ name: 'Home' })
      } catch (err) {
        if (err.response.data) {
          this.$toast.error(errorMessages(err.response.data.message))
          return
        }
        this.$toast.error(this.$t('somethingWentWrong') as string)
      } finally {
        loader.hide()
      }
    },
  },
  computed: {
    isEnabled() {
      return process.env.VUE_APP_QUICK_SIGNIN && process.env.VUE_APP_QUICK_SIGNIN === 'true'
    },
  },
})
